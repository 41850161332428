import {Controller} from '@hotwired/stimulus';
import {ConfirmSwal} from '@js/helpers/swal_helper';
import {Select2Helper} from '@js/helpers/select2_helper';
import {SyntaxValidator} from '@js/plugins/syntax_validator';

export default class extends Controller {
    static targets = [
        'appellationSelect',
        'cuveeName',
        'domainName',
        'colorTypeId',
        'millesimeYear',
        'sampleTypeId',
    ];

    appellationSelectTargetConnected(element) {
        const appellationSelectHelper = new Select2Helper(`/${window.lang()}/items/appellation`, {formatType: 'appellation'});
        appellationSelectHelper.initializeSelect2(element);
    }

    fillInputs(event) {
        const data = event.detail.selected.dataset;
        this.domainNameTarget.value = data.cuveeDomain;
        this.cuveeNameTarget.value = data.cuveeCuvee;
        this.colorTypeIdTarget.value = data.cuveeColorTypeId;

        // Trigger select2 changes
        // Color type select
        $(this.colorTypeIdTarget).trigger('change');
        // Appellation select
        let option = new Option(data.cuveeAppellationFullname, data.cuveeAppellationId, true, true);
        option.setAttribute('data-iso-code', data.cuveeAppellationIsoCode);
        $(this.appellationSelectTarget).append(option).trigger('change');

        // Go to the millesime field
        this.millesimeYearTarget.scrollIntoView();

        // Clear the search field
        document.getElementById('search').value = '';
    }

    // function called on form submit to validate identification syntax
    async validateSyntax(event) {
        event.preventDefault();

        if (!event.target.form.checkValidity()) {
            event.target.form.reportValidity();
            return;
        }

        const currentTargetDataset = event.currentTarget.dataset;

        // Display a confirmation modal if needed
        if (currentTargetDataset.turboConfirm) {
            const confirmed = await new ConfirmSwal(JSON.parse(currentTargetDataset.turboConfirm)).fire();
            if (!confirmed) {
                return;
            }
        }

        // Call syntax validator popup
        const syntaxValidator = new SyntaxValidator(
            this.cuveeNameTarget,
            this.domainNameTarget,
            () => this.cuveeNameTarget.form.requestSubmit(),
            JSON.parse(currentTargetDataset.translations)
        );

        await syntaxValidator.validateSyntax();
    }
}
