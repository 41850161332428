import {get, post} from '@rails/request.js';
import {HTMLCustomSwal} from '@js/helpers/swal_helper';

export class SyntaxValidator {
    constructor(cuveeNameTarget, domainNameTarget, submitFormCallback, translations) {
        this.cuveeNameTarget = cuveeNameTarget;
        this.domainNameTarget = domainNameTarget;
        this.submitFormCallback = submitFormCallback;
        this.translations = translations;
    }

    syntaxValidatorTriggered = false

    async validateSyntax() {
        const response = await get('/comments/valid_identification', {
            query: {cuvee: this.cuveeNameTarget.value, domain: this.domainNameTarget.value},
            responseKind: 'json',
        });

        if (response.ok) {
            const data = await response.json;
            if (data.values !== null) {
                this.syntaxValidatorTriggered = true;
                await this.syntaxAlert(data.values, this.translations);
            } else {
                this.submitFormCallback();
            }
        }

        return response;
    }

    async syntaxAlert(values, translations) {
        const response = await post(`/${window.lang()}/comments/syntax_selector`, {
            body: {
                current: {
                    cuvee: this.cuveeNameTarget.value,
                    domain: this.domainNameTarget.value,
                },
                suggestions: values,
            },
            responseKind: 'html',
        });

        if (response.ok) {
            const html = await response.html;
            const {value: newSyntax} = await new HTMLCustomSwal(translations).fire({
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                    icon: 'fs-13'
                },
                html: html,
                icon: 'warning',
                showCloseButton: true,
                preConfirm: () => {
                    const htmlContainer = Swal.getHtmlContainer();
                    const checkedCheckbox = htmlContainer.querySelector('input[type="checkbox"]:checked');

                    if (!checkedCheckbox) {
                        return Swal.showValidationMessage(translations.validation);
                    }

                    const selectedDomain = checkedCheckbox.nextElementSibling.querySelector('[data-domain-name]').textContent;
                    const selectedCuvee = checkedCheckbox.nextElementSibling.querySelector('[data-cuvee-name]').textContent;

                    return {cuvee: selectedCuvee, domain: selectedDomain};
                },
            });

            if (newSyntax) {
                this.domainNameTarget.value = newSyntax.domain;
                this.cuveeNameTarget.value = newSyntax.cuvee;

                this.submitFormCallback();
            }
        }
    }
}
